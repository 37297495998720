.selectedLink {
  color: #4fc3fd !important;
}

@media (max-width: 900px) {
  .selectedLink {
    color: white !important;
    font-weight: 500 !important;
  }
}

.tooltip {
  background-color: white !important;
  border: 1px solid #f87171;
  color: black !important;
  margin: 10px !important;
  font-family: Poppins, Inter, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.tooltip span {
  color: #f87171;
}

* {
   -webkit-user-select : none;
  -moz-user-select : none;
  -ms-user-select : none;
  user-select : none; 
}